import React from "react";
import Layout from "../components/Layout";
import PricingTable from "../components/PricingTable";

function Prices() {
    return (
        <Layout>
            <div id="main">
                <PricingTable/>
            </div>
        </Layout>
    )
}

export default Prices
